import useAmISignedIn from '@beatgig/auth-hooks/use-am-i-signed-in'
import React from 'react'
import { AuthFlow } from 'react-native-doorman'
import { ActivityIndicator, Text } from 'react-native'
import useSignOut from '@beatgig/auth-hooks/use-sign-out'

type Props = {}

export default function AuthFlowScreen(props: Props) {
  const {} = props

  const { loading, amISignedIn } = useAmISignedIn()
  const signOut = useSignOut()
  if (loading) return <ActivityIndicator />
  if (amISignedIn) return <Text onPress={signOut}>Signed In!</Text>

  return <AuthFlow />
}
